<template>
    <div>
        <h1>Championnat de France<br>de Cerf-Volant Acrobatique</h1>
        <h2>Informations générales</h2>
        <p>Le Championnat de France de Cerf-Volant Acrobatique repose sur un fonctionnement en 2 étapes sur, généralement, 2 années civiles.<br>
        Des manches qualificatives sont organisées chaque année qui donnent lieu à un classement national "provisoire" mis à jour au fil de l'eau.<br/>
        A l'issue de celles-ci, la commission compétition statut sur les compétiteurs qualifiés pour la phase finale.<br/>
        Celle-ci se déroule généralement l'année suivant en deux à trois manches réalisées sur 3 jours en un seul lieu.<br/>
        Le règlement utilisé pour cette compétition, consultable <b><a rel="prefetch" :href="reglementUrl" target="_blank">ici</a></b>, est français, bien que librement et assez largement inspiré d'un autre règlement (IRBC).
        </p>
        <note v-model="exec">Exécution</note>
        <note v-model="choreo">Chorégraphie</note>
        <p>Choégraphie : {{choreo}} / Exécution : {{ exec }}</p>
        <pdf src="<%=https://cv.ffvl.fr/sites/cv.ffvl.fr/files/Reglement-CVA-2022.pdf" />
        <h2>Infos pratiques pour la saison 2023</h2>
        <ul>
            <li>Règles applicables en 2023</li>
            <li>Figures de précision 2023</li>
        </ul>
        <h2>Finale du Championnat de France 2023</h2>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import pdf from 'vue-pdf';

import Note from '@/components/Note.vue';

export default{
    components: { pdf, Note },
    data(){
        return {
            //reglementUrl: '',
            exec: 72,
            choreo: 65,
        }
    },
    computed:{
    ...mapGetters("config", ["serverBaseUrl"]),
    reglementUrl(){ return this.serverBaseUrl + '/Reglement-CVA-2022.pdf'; },
    
    },
    methods:
    {
        async refresh(){
            //this.reglementUrl = (await import('@/assets/Reglement-CVA-2022.pdf')).default;
            //this.reglementUrl =  "@/assets/Reglement-CVA-2022.pdf";
        },
    },
    async mounted(){
      await this.showLoader();
      await this.refresh();
      this.$hideLoader();
    }

}
</script>