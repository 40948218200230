<template>
    <div class="ctl">
        <slot></slot>
        <div class="wheeler" @wheel="onWheel" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">{{ dizain }}<span class="unit">0</span></div>
        <div class="wheeler" @wheel="onWheelUnit" @touchstart="onTouchStartUnit" @touchmove="onTouchMoveUnit" @touchend="onTouchEndUnit">{{ (dizain != 10) ? unit : 0 }}</div>
    </div>
</template>

<script>

export default{
    model:{
        prop: "value",
        event: "change",
    },
    props:{
        value: { default: 0},
    },
    data(){
        return {
            dizain: Math.floor(this.value / 10),
            unit: this.value % 10,
            yTouchStart: 0,
            yTouchUnitStart:0,
            yTouch: 0,
            yTouchUnit: 0
        }
    },
    methods:{
        onWheel: function(e){
            e.preventDefault();
            var val = this.dizain -= Math.sign(e.deltaY);
            if(val > 10) val = 0;
            if(val < 0) val = 10;
            this.dizain = val;
            this.$emit("change", (this.dizain * 10) + (this.dizain != 10 ? this.unit : 0));
        },
        onWheelUnit: function(e){
            e.preventDefault();
            if(this.dizain == 10) return;
            var val = this.unit -= Math.sign(e.deltaY);
            if(val > 9) val = 0;
            if(val < 0) val = 9;
            this.unit = val;
            this.$emit("change", (this.dizain * 10) + (this.dizain != 10 ? this.unit : 0));
        },
        onTouchStart: function(e){
            e.preventDefault();
            //console.log('touchStart', e.touches[0]);
            this.yTouchStart = e.touches[0].pageY
        },
        onTouchStartUnit: function(e){
            e.preventDefault();
            //console.log('touchStartUnit', e);
            this.yTouchUnitStart = e.touches[0].pageY
        },
        onTouchMove: function(e){
            e.preventDefault();
            //console.log('touchMove', e.touches[0]);
            this.yTouch = e.touches[0].pageY
        },
        onTouchMoveUnit: function(e){
            e.preventDefault();
            //console.log('touchMouveUnit', e);
            this.yTouchUnit = e.touches[0].pageY
        },
        onTouchEnd: function(e){
            e.preventDefault();
            //console.log('touchEnt', e);
            //var yTouch = e.touches[e.touches.length-1].pageY;
            //var val = this.dizain -= Math.sign(yTouch - this.yTouch);
            var val = this.dizain -= Math.sign(this.yTouch - this.yTouchStart);
            if(val > 10) val = 0;
            if(val < 0) val = 10;
            this.dizain = val;
            this.$emit("change", (this.dizain * 10) + (this.dizain != 10 ? this.unit : 0));
        },
        onTouchEndUnit: function(e){
            e.preventDefault();
            //console.log('touchEndUnit', e);
            //var yTouch = e.touches[e.touches.length-1].pageY;
            //var val = this.unit -= Math.sign(yTouch -  this.yTouchUnit);
            var val = this.unit -= Math.sign(this.yTouchUnit -  this.yTouchUnitStart);
            if(val > 9) val = 0;
            if(val < 0) val = 9;
            this.unit = val;
            this.$emit("change", (this.dizain * 10) + (this.dizain != 10 ? this.unit : 0));
        }
    }
}
</script>

<style scoped>
    div.ctl{
        width: 100; height:100;
        display: flex;
        border: solid 1px black;
    }
    div.wheeler{
        color:red;
        font-size:80px;
        border:solid 1px grey;
        width:60px !important;
        height: 100px !important;
    }
    span.unit{
        color:gray;
        font-size:30px;
    }
</style>